import { addNavMenuItem } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem({
        id: 'stock-export',
        label: 'Stock export',
        routerLink: ['/extensions/stock-export'],
        icon: 'euro',
    },
        'sales'),
];