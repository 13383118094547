export const extensionRoutes = [  {
    path: 'extensions/invoice-export',
    loadChildren: () => import('./extensions/cd2cea82cf5a91dfb182bb353ea25c8d0ed6e357d1da0c6471d6b87df3af463f/routes'),
  },
  {
    path: 'extensions/orders-extended',
    loadChildren: () => import('./extensions/fa7c6b8d69f54965913c2e5eb76c937dcc88b29347a87dda94b05772245039f1/routes'),
  },
  {
    path: 'extensions/tax-export',
    loadChildren: () => import('./extensions/5b355a46194ddcb51bf1b3d934529adef519d9ea6dc8cc16719f77866a0aa71f/routes'),
  },
  {
    path: 'extensions/stock-export',
    loadChildren: () => import('./extensions/9323bbc95c48fffd09f6d2702be73a390a78fdc6cbf3b7b2b0c3938489a9e9ff/routes'),
  }];
