import { NgModule } from '@angular/core';
import { SharedModule, addActionBarItem, ActionBarContext, NotificationService } from '@vendure/admin-ui/core';

// FIXME: figure out codesharing
export const DEV_HOST = "https://dev.nf-eu-west.keeb.supply";
export const PROD_HOST = "https://prod.nf-eu-west.keeb.supply";

const isLocalhost = (hostname: string) => {
    if (hostname == "localhost") {
        return true;
    }

    return false;
}

function isDevelopment(hostname: string) {
    return hostname === 'dev-admin.keeb.supply'
}

const callRoute = (view: Window, relativeRoute: string, method: string, json: boolean) => {
    let token = view.window.localStorage.getItem("vnd__authToken");
    if (!token) {
        throw new Error('Couldn\'t retrieve token');
    }
    token = token.replace(/\"/g, "");

    const isLocal = isLocalhost(view.window.location.hostname);
    const isDev = isDevelopment(view.window.location.hostname);

    // TODO: should eventually be agnostic & moved to backend
    const apiRoute = isLocal ? `${view.window.location.origin}${relativeRoute}` :
        (isDev ? `${DEV_HOST}${relativeRoute}` : `${PROD_HOST}${relativeRoute}`);

    return fetch(apiRoute, {
        method,
        headers: new Headers(
            {
                Authorization: `Bearer ${token}`
            }
        )
    }).then(async (resp) => {
        const text = await resp.text();
        if (resp.status != 200 && resp.status != 201) {
            throw new Error(text)
        }

        return json ? JSON.parse(text) : text;
    });
}

function handleError(err: Error, notificationService: NotificationService) {
    notificationService.error(`An error occured: ${err.message}`);
    console.error(err);
}

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        addActionBarItem({
            id: "order-invoice-recreate-button",
            label: "Recreate Invoice",
            locationId: "order-detail",
            buttonStyle: "outline",
            onClick: (event: MouseEvent, context: ActionBarContext) => {

                const view = event.view;
                const id = context.route.snapshot.params.id;

                if (!id || !view) {
                    return;
                }

                callRoute(view, `/invoice-admin/${id}/recreate`, 'POST', true)
                    .then(json => {
                        if (json.didRecreate) {
                            context.notificationService.success("Successfully recreated invoice!")
                        } else {
                            context.notificationService.warning("Order didn't change - no recreation necessary")
                        }
                    })
                    .catch(err => handleError(err, context.notificationService))

            },
            requiresPermission: "SuperAdmin"
        }),
        addActionBarItem({
            id: "order-invoice-print-button",
            label: "Print Invoice",
            locationId: "order-detail",
            buttonStyle: "outline",
            onClick: (event: MouseEvent, context: ActionBarContext) => {

                const view = event.view;
                const id = context.route.snapshot.params.id;

                if (!id || !view) {
                    return;
                }

                callRoute(view, `/invoice-admin/${id}`, 'GET', false).then(url => {
                    const newView = view.window.open(url);
                    if (newView) {
                        newView.focus();
                    }
                }).catch(err => handleError(err, context.notificationService))

            },
            requiresPermission: "SuperAdmin"
        })
    ]
})

// @ts-ignore
export class InvoiceButtonsModule { }