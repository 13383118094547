import { addNavMenuItem } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem({
        id: 'invoice-export',
        label: 'Invoice export',
        routerLink: ['/extensions/invoice-export'],
        icon: 'euro',
    },
        'sales'),
];